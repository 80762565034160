/* eslint no-console:0 */

import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"

import "@rails/actiontext"

import moment from 'moment'
window.moment = moment

import morphdom from 'morphdom'

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"

import LocalTime from "local-time"

LocalTime.config.i18n["de"] = {
  
  date: {
    dayNames: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag"
    ],
    abbrMonthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    yesterday: "gestern",
    today: "heute",
    tomorrow: "morgen",
    on: "am {date}",
    formats: {
      default: "%e. %b %Y",
      thisYear: "%e. %b"
    }
  },
  time: {
    singular: "einer {time}",
    singularAn: "einer {time}",
    elapsed: "Vor {time}",
    second: "Sekunde",
    seconds: "Sekunden",
    minute: "Minute",
    minutes: "Minuten",
    hour: "Stunde",
    hours: "Stunden",
    formats: {
      default: "%l:%M%P"
    }    
  }
}

LocalTime.config.locale = "de"

require("local-time").start()

// Handle frame-missing events gracefully for redirects, like in Turbo 7.2
// document.addEventListener("turbo:frame-missing", function (event) {
//   if (event.detail.response.redirected) {
//     event.preventDefault()
//     event.detail.visit(event.detail.response)
//   }
// })
