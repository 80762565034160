import { Slideover } from "tailwindcss-stimulus-components"

export default class extends Slideover {
  connect() {
    super.connect()
    
    this.closeIfOpen = () => {
      if (this.dialogTarget && this.dialogTarget.open) {
        this.hide()
      }
    }

    document.addEventListener("turbo:before-cache", this.closeIfOpen)
    document.addEventListener("turbo:before-visit", this.closeIfOpen)
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.closeIfOpen)
    document.removeEventListener("turbo:before-visit", this.closeIfOpen)
    super.disconnect()
  }
}