import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Compressor from 'compressorjs';

export default class extends Controller {
  static targets = ["input", "previewContainer", "attachmentsFields", "progressBar", "progressBarWrapper", "fileUploadContainer"];
  static values = { fieldName: String }; // Add a value for the hidden field name

  connect() {
    console.log("File upload controller connected");
    this.element.addEventListener("submit", this.handleSubmit.bind(this));

    // Check if fileUploadContainerTarget exists before adding event listener
    if (this.hasFileUploadContainerTarget) {
      this.fileUploadContainerTarget.addEventListener("click", this.triggerFileInput.bind(this));
    }
  }

  triggerFileInput(event) {
    this.inputTarget.click();
  }

  uploadFiles(event) {
    console.log("Uploading files...");
    const files = this.inputTarget.files;
    Array.from(files).forEach(file => {
      if (file.type.startsWith("image/")) {
        // Log file info
        console.log(`Attempting to compress image: ${file.name}, Size: ${file.size}, Type: ${file.type}`);
  
        try {
          // Compress the image before uploading
          new Compressor(file, {
            quality: 0.8,
            success: (compressedFile) => {
              console.log("Image compressed successfully:", compressedFile);
              this.previewFile(compressedFile);
              this.uploadFile(compressedFile);
            },
            error: (err) => {
              console.error("Image compression failed:", err);
            },
          });
        } catch (err) {
          console.error("Error during compression:", err);
        }
      } else {
        // For non-image files, skip compression and upload directly
        console.log("Skipping compression, non-image file:", file.name);
        this.previewFile(file);
        this.uploadFile(file);
      }
    });
  }

  previewFile(file) {
    const fileType = file.type.split('/')[0]; // e.g., 'image', 'video', 'audio'
    console.log("Previewing file:", file.name, fileType);

    const listItem = document.createElement("li");
    listItem.classList.add("relative");

    const containerDiv = document.createElement("div");
    containerDiv.classList.add("peer", "group", "block", "w-full", "aspect-w-10", "aspect-h-7", "rounded-lg", "bg-gray-100", "focus-within:ring-2", "focus-within:ring-offset-2", "focus-within:ring-offset-gray-100", "focus-within:ring--primary", "overflow-hidden");

    if (fileType === 'image') {
      // Preview images
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.classList.add("object-cover", "pointer-events-none");
        containerDiv.appendChild(img);
      };
      reader.readAsDataURL(file);
    } else if (fileType === 'video') {
      // Check if the browser can play the video type
      const video = document.createElement("video");
      if (video.canPlayType(file.type)) {
        // Create a video placeholder if the format is supported
        video.setAttribute("controls", "controls");
        video.classList.add("object-cover", "pointer-events-none");

        const source = document.createElement("source");
        source.src = URL.createObjectURL(file); // Use object URL for the video preview
        source.type = file.type;

        video.appendChild(source);
        containerDiv.appendChild(video);
      } else {
        // If the video format is not supported, show a placeholder
        const placeholder = document.createElement("div");
        placeholder.classList.add("video-placeholder", "w-full", "h-full", "bg-gray-200", "flex", "items-center", "justify-center");

        const videoIcon = document.createElement("span");
        videoIcon.textContent = "Unsupported video format"; // Placeholder text or icon
        placeholder.appendChild(videoIcon);

        containerDiv.appendChild(placeholder);
      }
    } else if (fileType === 'audio') {
      // Create an audio placeholder
      const audio = document.createElement("audio");
      audio.setAttribute("controls", "controls");
      audio.classList.add("pointer-events-none");

      const source = document.createElement("source");
      source.src = URL.createObjectURL(file); // Use object URL for the audio preview
      source.type = file.type;

      audio.appendChild(source);
      containerDiv.appendChild(audio);
    } else {
      // For other files, show a generic file icon or placeholder
      const placeholder = document.createElement("div");
      placeholder.classList.add("file-placeholder", "w-full", "h-full", "bg-gray-200", "flex", "items-center", "justify-center");

      const fileTypeIcon = document.createElement("span");
      fileTypeIcon.textContent = file.type; // Optionally, use a font icon library here
      placeholder.appendChild(fileTypeIcon);

      containerDiv.appendChild(placeholder);
    }

    listItem.appendChild(containerDiv);

    const fileName = document.createElement("p");
    fileName.classList.add("mt-2", "block", "text-sm", "font-medium", "text-gray-900", "truncate", "pointer-events-none");
    fileName.textContent = file.name;
    listItem.appendChild(fileName);

    const fileSize = document.createElement("p");
    fileSize.classList.add("block", "text-sm", "font-medium", "text-gray-500", "pointer-events-none");
    fileSize.textContent = `${(file.size / 1024).toFixed(2)} KB`; // Convert size to KB
    listItem.appendChild(fileSize);

    this.previewContainerTarget.appendChild(listItem);
  }

  uploadFile(file) {
    const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this);

    // Show progress bar
    this.progressBarWrapperTarget.style.display = "block";
    
    upload.create((error, blob) => {
      if (error) {
        console.error("Direct upload failed:", error);
      } else {
        // Create hidden field for the uploaded file's signed ID
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");

        // Use the dynamic field name from the value passed to the controller
        const fieldName = this.fieldNameValue || "attachments[]"; // Default to attachments[]
        hiddenField.setAttribute("name", fieldName);
        hiddenField.setAttribute("value", blob.signed_id);
        this.attachmentsFieldsTarget.appendChild(hiddenField);

        // Clear the file input after uploading
        this.inputTarget.value = ""; // This allows users to add more files

        // Hide progress bar after upload is complete
        this.progressBarWrapperTarget.style.display = "none";
      }
    });
  }

  // DirectUpload method to hook into the upload process
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.updateProgress(event));
  }

  updateProgress(event) {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      this.progressBarTarget.value = progress;
    }
  }

  handleSubmit(event) {
    // Disable the file input to prevent it from being submitted again
    this.inputTarget.disabled = true;
  }
}
