import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "id", "project_id", "protocol_id" ]

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      onEnd: this.end.bind(this),
    })
  }

  async end(event) {
    let url = this.element.dataset.dragUrl
    let id = event.item.dataset.id
    let project_id = event.item.dataset.project_id
    let protocol_id = event.item.dataset.protocol_id
    let position = event.newIndex + 1

    const request = new FetchRequest('PATCH', url.replace(":id", id).replace(":project_id", project_id).replace(":protocol_id", protocol_id), {
      body: JSON.stringify({ position: position }),
      contentType: 'application/json',
      responseKind: 'json'
    })

    const response = await request.perform()
    if (response.ok) {
      // do nothing
    } else {
      console.error('Error:', response)
    }
  }
}